import sanitizeHtml, { IOptions } from 'sanitize-html';

/**
 * @function
 * @description sets string to lower case and removes accents hooks (cedillas) and newlines
 * @param str string to be formatted
 */
export const normalizeString = (str: string | any) => {
   if (typeof str === 'string') {
      return str
         .normalize('NFD')
         .replace(/[\u0300-\u036f]/g, '')
         .replace(/\n/g, ' ')
         .toLowerCase();
   }
   return str;
};

const htmlSanitizerOptions: IOptions = {
   allowedTags: ['br'],
};

/**
 * @function
 * @description Function used to keep line breaks in comments when they are rendered with [innerHtml]
 * @param {string} str comment string
 */
export const toHtml = (str: string): string => {
   return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
};

/**
 * @function
 * @description sanitizes html string that can then be used in React dangerouslySetInnerHtml
 * @param {string} dirty string to be sanitized
 */
export const sanitizeHtmlString = (dirty: string): { __html: string } => ({
   __html: sanitizeHtml(dirty, htmlSanitizerOptions),
});

export const returnTop = () => {
   window.scroll({ top: 0, left: 0, behavior: 'smooth' });
};
