import { useState, useEffect } from 'react';
import { getMedia } from '../../API/api';
import Loader from 'react-loader-spinner';
import './media.scss';
import imageNotFound from '../../assets/images/image-not-found.jpg';

interface ParentProps {
   alt: string;
   path: string | undefined;
   directSource: string | undefined;
   video?: boolean;
}

const Media = (props: ParentProps) => {
   const { alt, path, directSource, video } = props;

   const [src, setSrc] = useState<string>('');
   const [imageTransitionLoad, setImageTransitionLoad] = useState<boolean>(false);

   useEffect(() => {
      !!src && !video && !directSource && setImageTransitionLoad(true);

      if (!!path) {
         (async () => {
            try {
               const prevSrc = !!src ? src : undefined;
               const response = await getMedia(path);
               setSrc(URL.createObjectURL(response));
               setImageTransitionLoad(false);
               !!prevSrc && URL.revokeObjectURL(prevSrc);
            } catch (error) {
               setImageTransitionLoad(false);
               setSrc(imageNotFound);
            }
         })();
      }

      if (!!directSource) {
         setSrc(directSource);
      }

      return () => {
         URL.revokeObjectURL(src);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [path, directSource]);

   return (
      <div className="imgContainer">
         {(!src || !!imageTransitionLoad) && <Loader className="s-loader" type="TailSpin" color="#000" height={50} width={50} timeout={5000} />}
         {!video ? (
            <img src={src} alt={alt} />
         ) : (
            <video src={src} controls>
               Sorry, your browser doesn't support embedded videos.
            </video>
         )}
      </div>
   );
};

export default Media;
