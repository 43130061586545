import { useContext, useEffect, useState } from 'react';
import './context-media-field-edition.scss';

import MediaCarousel from '../../components/MediaCarousel';
import MediaList from '../../components/MediaList';

import * as contextService from '../../core/helpers/contextHelper';
import { getDateName } from '../../core/helpers/datesHelper';
import { checkIsVideo, populateMedia, processMedia } from '../../core/helpers/mediaHelper';
import strings from '../../core/localization/localization';
import { MessageContext } from '../../react-context/message-context';

import config from '../../config/config';

const ContextMediaFieldEdition = (props) => {
   const { index, data, idDisplayWZ, isLoading } = props;

   const PHOTO = 'PHOTO';

   const mediaWarningMessage = (
      <>
         {strings.pwa_upload_warning}
         <br />• {strings.pwa_should_not_exceed_file_amount} ({config.maximumFilesPerContext})
         <br />• {strings.pwa_do_not_load_more_than} {config.maximumVideosPerContext} {strings.pwa_videos}
         <br />• {strings.pwa_do_not_exceed_size} {config.maximumVideoSize} {strings.pwa_mo_per_file}
      </>
   );

   const { setMessage, clearMessage } = useContext(MessageContext);

   const [photos, setPhotos] = useState([]);
   const [selectedMedia, setSelectedMedia] = useState(undefined);

   useEffect(() => {
      (async () => {
         const photoList = await populateMedia(data, idDisplayWZ, index);
         setPhotos(photoList);
      })();
   }, [data, idDisplayWZ, index]);

   const removePhoto = async (e, photoIndex, photo) => {
      e.stopPropagation();
      e.preventDefault();

      const p = [...photos];
      p.splice(photoIndex, 1);

      contextService.saveTempValue(p, PHOTO);
      if (photo.isSavedInStorage) {
         contextService.removeMedia(photo.value);
      }

      setPhotos(p);
      clearMessage();
   };

   const handleOnChange = async (event) => {
      clearMessage();

      if (!event.target.files.length) return;

      const files = Array.from(event.target.files);
      const dateName = getDateName();
      let videoCount = photos.reduce((acc, { video }) => acc + +!!video, 0);

      const fileObjs = [];

      let shouldWarn = false;

      for (let i = 0; i < files.length; i += 1) {
         const fileValue = files[i];

         if (i + 1 + photos.length <= config.maximumFilesPerContext) {
            videoCount += +checkIsVideo(fileValue.name);

            if (videoCount <= config.maximumVideosPerContext && fileValue.size <= config.maximumVideoSize * 1024 * 1024) {
               const splitName = fileValue.name.split('.');
               fileObjs.push({
                  value: `${idDisplayWZ}_${dateName}_${i + 1}.${splitName[splitName.length - 1]}`,
                  metadata: { file: fileValue },
               });
            } else {
               shouldWarn = true;
            }
         } else {
            shouldWarn = true;
            break;
         }
      }

      const newPhotos = await processMedia(fileObjs);
      const allPhotos = [...photos, ...newPhotos];
      shouldWarn && setMessage({ text: mediaWarningMessage });
      setPhotos(allPhotos);
      contextService.saveTempValue(allPhotos, PHOTO);

      return null;
   };

   return (
      <>
         <div className="content_container">
            <div className="Container_category-edition">
               <div className="Container_category-title">{strings.pwa_photo_video}</div>
               <div className="category_content">
                  <MediaList photos={photos} index={index} idDisplayWZ={idDisplayWZ} data={data} removePhoto={removePhoto} openCarousel={(i) => setSelectedMedia(i)} />
               </div>
            </div>
            <label className={`add-media-button ${isLoading ? 'add-media-button--disabled' : ''}`} htmlFor="context-media-upload">
               {strings.pwa_select_media_for_upload}
            </label>
            <input
               type="file"
               disabled={isLoading}
               onChange={handleOnChange}
               onClick={(e) => (e.target.value = '')}
               name="uploadPhoto"
               id="context-media-upload"
               accept="video/*,image/*,text/plain"
               multiple
            />
         </div>
         {typeof selectedMedia !== 'undefined' && <MediaCarousel mediaList={photos} selectedMedia={selectedMedia} closeCarousel={() => setSelectedMedia(undefined)} />}
      </>
   );
};

export default ContextMediaFieldEdition;
