import { Component } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import strings from '../../core/localization/localization';

class TabNavContext extends Component {
   tabChange = (event, value) => {
      this.props.callbackIndex(value);
   };

   fulfillContext() {
      this.props.callbackFulfill(this.props.activeIndex);
   }

   showButtonPreFilling() {
      return (
         <button
            className="preFilling-button"
            onClick={() => {
               this.fulfillContext();
            }}
         >
            {strings.pwa_Prefill_from_the_Main_Context}
         </button>
      );
   }

   getSubContext() {
      if (this.props.Contexts !== undefined && this.props.Contexts.subcontextList !== undefined) {
         return this.props.Contexts.subcontextList.map((data) => {
            let idx = this.props.Contexts.subcontextList.indexOf(data) + 1;
            return <Tab className="ContextTab" key={idx} label={undefined === data.name || null === data.name ? strings['pwa_sub_context'] + ' ' + idx : data.name} />;
         });
      }
      return [];
   }

   render() {
      let { activeIndex } = this.props;
      if (this.props.Contexts === undefined || this.props.Contexts.subcontextList === undefined) {
         activeIndex = 0;
      }
      return (
         <div>
            <Tabs
               classes={{ root: 'ContextTabsContainer', scrollButtons: 'ContextTabsScrollButtons', indicator: 'ContextTabsIndicator' }}
               value={activeIndex}
               onChange={this.tabChange}
               variant="scrollable"
               scrollButtons="auto"
            >
               <Tab className="ContextTab" key="0" label={strings['pwa_main_context']} />
               {this.getSubContext()}
            </Tabs>

            {activeIndex !== 0 && <div>{this.showButtonPreFilling()}</div>}
         </div>
      );
   }
}

export default TabNavContext;
