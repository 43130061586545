import * as contextService from './contextHelper';

export function isVisibleField(field, id, index = 0) {
   if (field.sfield === null || field.sfield === undefined || field.sfield === [] || field.sfield.length === 0) {
      return Promise.resolve(true);
   }
   let display = true;

   return contextService.getUpdatedValues(id, index).then((listfields) => {
      field.sfield.forEach((condition) => {
         let condField = listfields.find((sf) => {
            return sf.id === condition.id;
         });
         if (undefined === condField || -1 === condition.value.indexOf(condField.value)) {
            display = false;
         }
      });
      return display;
   });
}

export function getCurrentContext(data, index) {
   let contexts;
   if (0 === index) {
      contexts = data.primaryContext;
   } else {
      contexts = data.subcontextList[index - 1];
   }
   return contexts;
}

export function getCurrentFieldRawValue(data, idDisplayWZ, index, idField) {
   let contexts = getCurrentContext(data, index);
   if (idField === 'PHOTO') {
      if (!!data.delta && !!data.delta.length && !!data.delta[0].values && !!data.delta[0].values.length) {
         const contextDelta = data.delta.find((d) => d.index === index);

         if (!!contextDelta) {
            const deltaPhotos = contextDelta.values.find(({ id }) => id === 'PHOTO');
            if (!!deltaPhotos) {
               return deltaPhotos.value;
            }
         }
      }

      return contexts.photos && !!contexts.photos.length ? contexts.photos.map((p) => ({ value: p })) : [];
   }

   let valueItem, indexDelta;
   if (undefined !== data.delta) {
      indexDelta = data.delta.find((item) => {
         return item.index === index;
      });
      if (!!indexDelta) {
         valueItem = indexDelta.values.find((item) => {
            return item.id === idField;
         });
         if (undefined !== valueItem) {
            return valueItem.value;
         }
      }
   }
   let listSubfields = contexts.listFields.map((subfields) => {
      return subfields.subfields.map((fields) => {
         return fields;
      });
   });
   if (!!listSubfields) {
      listSubfields.forEach((subField) => {
         let match = subField.find((item) => {
            return item.id === idField;
         });
         if (!!match) {
            valueItem = match;
            return;
         }
      });
      if (!!valueItem) {
         return valueItem;
      }
   }
   return undefined;
}

export function getCurrentFieldValue(data, idDisplayWZ, index, idField) {
   let rawValue = getCurrentFieldRawValue(data, idDisplayWZ, index, idField);
   if (!rawValue) return undefined;
   if ('PHOTO' !== idField && Array.isArray(rawValue)) {
      return rawValue.map((rv) => rv.value);
   }
   if ('PHOTO' === idField) {
      return rawValue;
   }
   return rawValue.value;
}

export function getFieldCondisp(currentFields, sf) {
   let condisp = true;

   if (Array.isArray(sf) && !!sf.length) {
      condisp = false;
      for (const cond of sf) {
         const parent = currentFields.find((ci) => cond.id === ci.id);
         if (!!parent && cond.value.includes(parent.value)) {
            condisp = true;
            break;
         }
      }
   }
   return condisp;
}
