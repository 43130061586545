let deferredPrompt: any;
export const promptButtonId = 'prompt-button';

export const interceptPrompt = (e: any) => {
   // Prevent the mini-infobar from appearing on mobile
   e.preventDefault();
   // Stash the event so it can be triggered later.
   deferredPrompt = e;

   const promptButton = document.getElementById(promptButtonId);

   let previouslyDenied: string | number | null = localStorage.getItem('deniedPwa');
   if (!!previouslyDenied) {
      previouslyDenied = +previouslyDenied;
   }

   // for desktop usage. don't prompt again within 12 weeks if user declines installation
   const timeSpan = 84 * 24 * 60 * 60 * 1000; // 12 weeks
   const canPrompt = !previouslyDenied ? true : Date.now() - timeSpan > previouslyDenied;

   if (!!promptButton && canPrompt) {
      localStorage.removeItem('deniedPwa');
      promptButton.style.display = 'flex';
   }
};

window.addEventListener('beforeinstallprompt', interceptPrompt);

export const installPwa = () => {
   if (!!deferredPrompt) {
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult: { outcome: 'accepted' | 'dismissed'; platform: string }) => {
         if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the install prompt');
         } else {
            localStorage.setItem('deniedPwa', `${Date.now()}`);
            console.log('User dismissed the install prompt');
         }
         const promptButton = document.getElementById(promptButtonId);
         if (!!promptButton) {
            promptButton.style.display = 'none';
         }
         window.removeEventListener('beforeinstallprompt', interceptPrompt);
      });
   }
};

// window.addEventListener('appinstalled', () => {
//     // Log install to analytics
//     console.log('INSTALL: Success');
// });

export const removePromptButton = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
   e.stopPropagation();
   e.preventDefault();
   const promptButton = document.getElementById(promptButtonId);
   if (!!promptButton) {
      promptButton.style.display = 'none';
   }
};
