import './header.scss';

import PersonIcon from '@material-ui/icons/Person';
import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import logo from '../../assets/images/logo.png';
import strings from '../../core/localization/localization';
import { SyncContext } from '../../react-context/sync-context';
import { UserContext } from '../../react-context/user-context';

interface ParentProps {
   updateTrigger: () => void;
}

const Header = ({ updateTrigger }: ParentProps) => {
   const path = useLocation().pathname;

   const { user } = useContext(UserContext);
   const { appLanguage, setAppLanguage } = useContext(SyncContext);

   const handleChange = (e: any) => {
      const val = e.target.value;
      localStorage.setItem('anais-pwa-localization', val);
      setAppLanguage(val);
      strings.setLanguage(val);
      updateTrigger();
   };

   const Home = ({ children }: { children: React.ReactNode }) => (path !== '/' ? <Link to="/">{children}</Link> : <a href="/">{children}</a>); // completely reload app if clicking logo while already on home page

   return (
      <header className="App-header">
         <div className="App-header_logo">
            <Home>
               <img src={logo} alt="Logo" />
            </Home>
         </div>
         <div className="App-header_Usercompte">
            <Link to="/account" className="account-link">
               <PersonIcon />
               <div className="App-header_User">{`${user.firstName.split('')[0]}. ${user.lastName}`}</div>
            </Link>
            <select onChange={handleChange} value={appLanguage} className={'App-header_lang'}>
               <option value="fr" className={'App-header_lang'}>
                  FR
               </option>
               <option value="en" className={'App-header_lang'}>
                  EN
               </option>
            </select>
         </div>
      </header>
   );
};

export default Header;
