import './modal.scss';
import CloseIcon from '@material-ui/icons/Close';

type Children = React.ReactNode;

const Dialog = ({ children }: { children: Children }) => (
   <>
      <div className="modal">{children}</div>
      <div className="modal-background" />
   </>
);

const Header = ({ children, close }: { children?: Children; close?: () => void }) => (
   <div className="modal__header">
      <div className="modal__header__text">{children}</div>
      {close && (
         <div className="modal__header__close-icon" onClick={close}>
            <CloseIcon />
         </div>
      )}
   </div>
);

const Text = ({ children }: { children: Children }) => <div className="modal__text">{children}</div>;

const CommentBox = ({
   children,
   maxlength,
   value,
   onChange,
}: {
   children?: Children;
   maxlength?: number;
   value: string;
   onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}) => (
   <div className="modal__comment">
      <div className="modal__comment__title">{children}</div>
      <textarea maxLength={maxlength} onChange={onChange} value={value} />
   </div>
);

const Footer = ({ children }: { children: Children }) => <div className="modal__footer">{children}</div>;

const Modal = { Dialog, Header, Text, CommentBox, Footer };

export default Modal;
