import Media from '../Media';
import './media-list.scss';

import { IPhoto } from '../../core/interfaces/IPhoto';

import RemoveIcon from '@material-ui/icons/RemoveCircle';
import playButton from '../../assets/images/play-button.png';

interface Props {
   photos: IPhoto[];
   removePhoto: (e: any, photoIndex: number, photoInfo: { value: string; isSavedInStorage: boolean }) => void;
   openCarousel?: (photoIndex: number) => void;
}

const MediaList = ({ photos, removePhoto, openCarousel }: Props) => {
   if (!!photos.length) {
      return photos.map((photo: IPhoto, photoIndex: number) => (
         <div key={`media-${photoIndex}`} onClick={() => !!openCarousel && openCarousel(photoIndex)} className={`Category-content_bloc bloc-photo`}>
            {(() => {
               let path: string | undefined;
               let directSource: string | undefined;
               if (!photo.metadata) {
                  path = `/context/${photo.src}`;
               } else {
                  directSource = photo.src;
               }
               return <Media path={path} directSource={directSource} alt={photo.value} />;
            })()}
            {!!photo.video && <img className="play-button" src={playButton} alt="▶" style={{ color: '#ffff00' }} />}
            {!!removePhoto && (
               <div className="remove-icon">
                  <RemoveIcon
                     onClick={(e) => {
                        removePhoto(e, photoIndex, { value: photo.value, isSavedInStorage: !photo.metadata });
                     }}
                  />
               </div>
            )}
         </div>
      ));
   }

   return null;
};

export default MediaList;
