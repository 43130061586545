import urls from '../../API/urls';
import { getCurrentFieldValue } from './conditionHelper';

const photoFileExt = ['png', 'jpg', 'gif', 'jpeg'];
const videoFileExt = ['mp4', 'ogg', 'webm', 'mov', 'flv', 'm4v', 'avi', 'mpg', 'wmv', 'ebm'];

export const populateMedia = (data, idDisplayWZ, index) => {
   const photoList = getCurrentFieldValue(data, idDisplayWZ, index, 'PHOTO');
   return processMedia(photoList);
};

export const processMedia = async (photoList) => {
   for (let i = 0; i < photoList.length; i += 1) {
      // ext1 is extension with original case (upper or lower)
      const { filename, ext, ext1 } = getFileNameAndExtension(photoList[i].value);

      const video = videoFileExt.indexOf(ext) >= 0;

      photoList[i].src = photoList[i].value;

      if (!!video) {
         photoList[i].src = `${filename}-video-s.png`;
         photoList[i].video = ext1;
      }
      if (!!photoList[i].metadata) {
         photoList[i].src = await generateTempImage(photoList[i].metadata.file, video);
      }
   }
   return photoList;
};

/* generate temp video cover (image) for video */
const getVideoCover = (file, seekTo = 0.0) => {
   return new Promise((resolve, reject) => {
      const videoPlayer = document.createElement('video');
      videoPlayer.setAttribute('src', URL.createObjectURL(file));
      videoPlayer.load();
      videoPlayer.addEventListener('error', (ex) => {
         reject('error when loading video file', ex);
      });
      videoPlayer.addEventListener('loadedmetadata', () => {
         if (videoPlayer.duration < seekTo) {
            reject('video is too short.');
            return;
         }
         setTimeout(() => {
            videoPlayer.currentTime = seekTo;
         }, 200);
         videoPlayer.addEventListener('seeked', () => {
            const canvas = document.createElement('canvas');
            canvas.width = videoPlayer.videoWidth;
            canvas.height = videoPlayer.videoHeight;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
            ctx.canvas.toBlob(
               (blob) => {
                  resolve(blob);
               },
               'image/jpeg',
               0.75 /* quality */
            );
         });
      });
   });
};

const generateTempImage = async (file, video) => {
   if (!!video) {
      const blob = await getVideoCover(file);
      const imageUrl = URL.createObjectURL(blob);
      return imageUrl;
   }
   return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
   });
};

const getFileNameAndExtension = (file) => {
   const re = /(.*)(?:\.([^.]+))$/;
   const parsedFilename = re.exec(file);
   const filename = parsedFilename[1];
   const ext1 = parsedFilename[2];
   const ext = ext1.toLocaleLowerCase();
   return { filename, ext, ext1 };
};

const getLowerCaseExt = (filename) => {
   const fileSplit = filename.split('.');
   return fileSplit[fileSplit.length - 1].toLocaleLowerCase();
};

export const checkIsPhoto = (filename) => {
   const ext = this.getLowerCaseExt(filename);
   return photoFileExt.indexOf(ext) >= 0;
};

export const checkIsVideo = (filename) => {
   const ext = getLowerCaseExt(filename);
   return videoFileExt.indexOf(ext) >= 0;
};

export const getContextMediaUrl = (fileName) => {
   return `${urls.BLOB}${fileName}`;
};
