import strings from '../localization/localization';

export const toTwoDigits = (input: number | string) => {
   return ('0' + input).slice(-2);
};

export const getDateName = () => {
   const today = new Date();
   const year = today.getFullYear();
   const month = today.getMonth();
   const day = today.getDate();
   const hour = today.getHours();
   const min = today.getMinutes();
   const sec = today.getSeconds();

   return `${year.toString()}${toTwoDigits(month + 1)}${toTwoDigits(day)}-${toTwoDigits(hour)}${toTwoDigits(min)}${toTwoDigits(sec)}`;
};

export const getShortDate = (date?: Date | string) => {
   date = !date ? new Date() : new Date(date);
   const yyyy = date.getFullYear();
   const mm = `${date.getMonth() + 1}`.padStart(2, '0');
   const dd = date.getDate().toString().padStart(2, '0');

   return `${dd}/${mm}/${yyyy}`;
};

export const getDate = (date?: Date | string) => {
   if (!date) {
      date = new Date();
   }

   return new Date(date).toLocaleDateString(strings.getLanguage(), { year: 'numeric', month: 'long', day: 'numeric' });
};

export const getTime = (date?: Date | string) => {
   if (!date) {
      date = new Date();
   }

   return new Date(date).toLocaleTimeString(strings.getLanguage(), { hour: '2-digit', minute: '2-digit' });
};
