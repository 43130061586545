import moment from 'moment';
import db from '../core/dexie/db';
/**
 *
 * @param {any} data
 * @param {import('../core/models/warningZone').WarningZone} za
 * @returns
 */
export async function updateOrCreate(data, za) {
   try {
      const mainContext = data[0];
      const idDisplayWZ = za.idDisplayWZ.toString();
      const idWarningZone = za.idWarningZone.toString();
      const date = moment().format('YYYY-MM-DDTHH:mm:ss');
      const subContexts = data.length > 1 ? data.slice(1, data.length) : [];
      const zaObject = {
         idDisplayWZ,
         idWarningZone,
         photoContext1: za.photoContext1,
         photoContext2: za.photoContext2,
         photoWork: za.photoWork,
         photoDetail: za.photoDetail,
         estimate: za?.estimate,
         estimateLabel: za?.estimateLabel,
         action: za?.action,
         actionLabel: za?.actionLabel,
         dateLastGet: date,
         dateLastPost: '',
         delta: [],
         deltaSaved: true,
         primaryContext: mainContext,
         subcontextList: subContexts,
      };
      const object = await db.infos.where('idDisplayWZ').equals(idDisplayWZ).first();
      if (!!object) {
         if (!object.deltaSaved) {
            zaObject.delta = object.delta;
            zaObject.deltaSaved = false;
            zaObject.primaryContext.pourcentageCompletion = object.primaryContext.pourcentageCompletion;
         }
         zaObject.id = object.id;
         await db.infos.put(zaObject);
      } else {
         await db.infos.put(zaObject);
      }

      return zaObject;
   } catch (e) {
      console.log(e);
      return false;
   }
}

export async function updateDelta(idDisplayWZ, newDelta) {
   const object = await db.infos.where('idDisplayWZ').equals(idDisplayWZ).first();
   if (!!object) {
      object.delta = newDelta;
      await db.infos.put(object);
   }
}

export function getAllZAWithDelta() {
   return db.infos
      .filter((za) => {
         return za.deltaSaved === false && za.delta.length > 0;
      })
      .toArray()
      .then((response) => response)
      .catch((error) => {
         console.log(`Dexie failure: ${error}`);
         return false;
      });
}

export function getZAObject(idDisplayWZ) {
   return db.infos.where('idDisplayWZ').equals(idDisplayWZ).first();
}

export function saveZa(za) {
   return db.infos.put(za);
}

export async function saveTemp(value, key) {
   let temp = await db.temp.where({ key }).first();
   if (temp !== undefined) {
      temp.value = value;
   } else {
      temp = { key, value, trick: 'trick' };
   }
   return db.temp.put(temp).then(() => {
      return true;
   });
}

export function getTemp() {
   return db.temp.where({ trick: 'trick' }).toArray();
}

export async function deleteTemp() {
   await db.temp.clear();
}

// creates/updates mediaToDelete item in temp. This is used to create list of photos to delete from Azure Storage
export async function deleteMedia(name) {
   let temp = await db.temp.where({ key: 'mediaToDelete' }).first();
   if (temp !== undefined) {
      temp.value = [...temp.value, name];
   } else {
      temp = { key: 'mediaToDelete', value: [name], trick: 'trick' };
   }
   db.temp.put(temp);
}

export async function deleteAllDelta(idDisplayWZ) {
   await db.infos
      .where({ idDisplayWZ: idDisplayWZ + '' })
      .first()
      .then(async (za) => {
         if (za !== undefined) {
            za.delta = [];
            await db.infos.put(za);
         }
      });
}

export async function setDeltaSaved(idDisplayWZ) {
   const za = await db.infos.where('idDisplayWZ').equals(idDisplayWZ).first();
   za.deltaSaved = true;
   db.infos.put(za);
}

export function cleanContextCollection(zaIdsDisplay) {
   zaIdsDisplay = zaIdsDisplay.map((idDisplayWZ) => `${idDisplayWZ}`);
   return db.infos.where('idDisplayWZ').noneOf(zaIdsDisplay).delete();
}

export function clearAllDatas() {
   return db.delete();
}
