import './context-category-field.scss';

import EditIcon from '@material-ui/icons/Edit';
import { Component } from 'react';
import { Link } from 'react-router-dom';

import { getCurrentFieldValue, isVisibleField } from '../../core/helpers/conditionHelper.js';
import * as contextService from '../../core/helpers/contextHelper';
import { populateMedia } from '../../core/helpers/mediaHelper';
import strings from '../../core/localization/localization';
import MediaList from '../MediaList';

class ContextCategoryField extends Component {
   constructor(props) {
      super(props);

      this.state = {
         catagoryId: '',
         data: '',
         lang: this.props.lang,
         visible: [],
         photos: [],
      };
      contextService.deleteTempValue();
   }

   componentDidMount() {
      this.update();
   }

   componentDidUpdate(prevProps) {
      if (prevProps.index !== this.props.index || prevProps.data !== this.props.data) {
         this.update();
      }
   }

   async update() {
      const structure = this.props.structure;
      let visible = {};

      for (const field of structure.subfields) {
         const display = await isVisibleField(field, this.props.id, this.props.index);
         visible = { ...visible, [field.id]: display };
      }

      this.setState({ visible });

      if (structure.type === 'PHOTO') {
         const photos = await populateMedia(this.props.data, this.props.id, this.props.index);
         this.setState({ photos });
      }
   }

   /* generate temp video cover (image) for video */
   getVideoCover = (file, seekTo = 0.0) => {
      return new Promise((resolve, reject) => {
         const videoPlayer = document.createElement('video');
         videoPlayer.setAttribute('src', URL.createObjectURL(file));
         videoPlayer.load();
         videoPlayer.addEventListener('error', (ex) => {
            reject('error when loading video file', ex);
         });
         videoPlayer.addEventListener('loadedmetadata', () => {
            if (videoPlayer.duration < seekTo) {
               reject('video is too short.');
               return;
            }
            setTimeout(() => {
               videoPlayer.currentTime = seekTo;
            }, 200);
            videoPlayer.addEventListener('seeked', () => {
               const canvas = document.createElement('canvas');
               canvas.width = videoPlayer.videoWidth;
               canvas.height = videoPlayer.videoHeight;
               const ctx = canvas.getContext('2d');
               ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
               ctx.canvas.toBlob(
                  (blob) => {
                     resolve(blob);
                  },
                  'image/jpeg',
                  0.75 /* quality */
               );
            });
         });
      });
   };

   showBlock() {
      const { index, structure, id, data } = this.props;

      if (structure.type === 'PHOTO') {
         if (!this.state.photos.length) {
            const ctx_undefined = 'context_format_default_undefined';
            return (
               <div className="Category-content_bloc">
                  <p>
                     {strings[ctx_undefined]}
                     <br />
                     <strong>{strings[ctx_undefined]}</strong>
                  </p>
               </div>
            );
         } else {
            return <MediaList photos={this.state.photos} data={data} id={id} index={index} />;
         }
      } else {
         return structure.subfields.map((subfield) => {
            if (true === this.state.visible[subfield.id]) {
               let value = getCurrentFieldValue(data, id, index, subfield.id);
               if (value === undefined || value === null || value === '' || value === 'context_format_default_undefined') {
                  value = 'context_format_default_undefined';
                  return (
                     <div className="Category-content_bloc" key={subfield.id}>
                        <p>
                           {strings[subfield.name]}
                           <br />
                           <strong>{strings[value]}</strong>
                        </p>
                     </div>
                  );
               } else {
                  return (
                     <div className="Category-content_bloc not-undefined" key={subfield.id}>
                        <p>
                           {strings[subfield.name]}
                           <br />
                           <strong>{subfield.type === 'texte' || subfield.type === 'input' ? value : strings[value]}</strong>
                        </p>
                     </div>
                  );
               }
            } else {
               return '';
            }
         });
      }
   }
   render() {
      const { data, index, structure, id } = this.props;
      return (
         <div className="Container_category">
            <Link
               to={`/Context/${id}/modify`}
               state={{
                  data,
                  index,
                  id,
                  structure: structure.subfields,
                  type: structure.type,
               }}
            >
               <div key={structure.type} className="Container_category-title">
                  <p>{strings[structure.name]}</p>
                  <EditIcon />
               </div>
               <div className="category_content">{this.showBlock()}</div>
            </Link>
         </div>
      );
   }
}

export default ContextCategoryField;
