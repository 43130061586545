import React from 'react';
import { Close } from '@material-ui/icons';
import './pwa-install-button.scss';

interface ParentProps {
   buttonId: string;
   installPwa: () => void;
   removePromptButton: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const PwaInstallButton = ({ buttonId, installPwa, removePromptButton }: ParentProps) => {
   const installMessage = navigator.language.startsWith('fr') ? `Ajouter Anais à l'écran d'accueil` : 'Add Anais to Home screen';

   return (
      <div id={buttonId} className="pwa-install-button" style={{ display: 'none' }} onClick={() => installPwa()}>
         <img src="/favicon.png" alt="" />
         {installMessage}
         <div className="remove-prompt" onClick={(e) => removePromptButton(e)}>
            <Close />
         </div>
      </div>
   );
};

export default PwaInstallButton;
