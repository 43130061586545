import axios from 'axios';

import { getToken, logoffWithoutContext } from '../core/authentication/authProvider';
import URLS from './urls';

/******* File for API calls *******/
/**
 *
 * @returns {Promise<import('../core/models/warningZone').WarningZone[]>}
 */
export async function getMissionWarningZones() {
   try {
      const response = await getFetchAPI(URLS.API_MISSION_WARNING_ZONES);
      if (!!response) {
         return response;
      }
      throw new Error('No response received'); // Rethrow if response is falsy
   } catch (error) {
      console.error('Error fetching mission warning zones:', error);
      return []; // Return an empty array as a fallback
   }
}

export const addPushSubscription = (pushSubscription) => {
   const subscription = JSON.stringify(pushSubscription);
   return apiPost(URLS.API_NOTIFICATIONS_SUBSCRIBE, { subscription });
};

export function getContextForZA(nbPage) {
   return getFetchAPI(URLS.API_CONTEXTS_ZA + nbPage);
}

/** @returns {Promise<any>} */
const getFetchAPI = async (url) => {
   try {
      const token = await getToken();
      const response = await axios.get(url, {
         headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 401) {
         throw new Error(`Server Error: ${response.status}`);
      } else {
         return response.data;
      }
   } catch (error) {
      if (error.response.status === 401) {
         logoffWithoutContext();
      }
      throw error;
   }
};

const getStreamAPI = async (url) => {
   try {
      const token = await getToken();
      const response = await axios.get(url, {
         headers: { Authorization: `Bearer ${token}` },
         responseType: 'blob',
      });
      return response.data;
   } catch (error) {
      console.log(error);
      throw error;
   }
};

/** @returns {Promise<any>} */
const apiPost = async (url, data) => {
   try {
      const token = await getToken();
      const response = await axios.post(url, data, {
         headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 401) {
         throw new Error(`Server Error: ${response.status}`);
      } else {
         return response;
      }
   } catch (error) {
      console.error('Error in apiPost:', error);
      // If the token is invalid, we force the user to disconnect
      if (error.response.status === 401) {
         logoffWithoutContext();
      }
      throw error;
   }
};

/** @returns {Promise<any>} */
const apiPut = async (url, data) => {
   try {
      const token = await getToken();
      return axios.put(url, data, {
         headers: { Authorization: `Bearer ${token}` },
      });
   } catch (error) {
      console.log(error);
      throw error;
   }
};

/** @returns {Promise<any>} */
const apiPatch = async (url, data) => {
   try {
      const token = await getToken();
      return axios.patch(url, data, {
         headers: { Authorization: `Bearer ${token}` },
      });
   } catch (error) {
      console.log(error);
      throw error;
   }
};

export function updateContext(body) {
   return apiPost(URLS.API_UPDATE_CONTEXT, body);
}

export function uploadFile(data) {
   return postFile(URLS.API_UPLOAD_FILE, data);
}

async function postFile(url, data) {
   const token = await getToken();
   try {
      await axios.post(url, data, {
         headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
         },
      });
      return true;
   } catch (err) {
      return false;
   }
}

/**
 * @function
 * @return {Promise<string>}
 */
export const getUserLicenceLanguage = async () => {
   const { name } = await getFetchAPI(`${URLS.API_LICENCE_LANGUAGE}`);
   return name;
};

export const getTranslations = async (folders) => {
   let strings = {};

   const queryArray = [];
   folders.forEach((folder, i) => queryArray.push(`${i > 0 ? '&' : '?'}folder${i + 1}=${folder}`));
   const translations = await getFetchAPI(`${URLS.API_TRANSLATIONS_FOLDERS}${queryArray.join('')}`);

   translations.forEach((translation) => {
      strings = {
         ...strings,
         [translation.language]: {
            ...strings[translation.language],
            [translation.label]: translation.translation,
         },
      };
   });

   return strings;
};

export const getAccessPWA = async () => {
   return getFetchAPI(`${URLS.API_GET_ACCESS_PWA}`);
};

export const getContextsFromZaList = (/** @type {number[]} */ idDisplayWZList) => {
   /** @type {string[]} */
   const queryArray = [];
   idDisplayWZList.forEach((/** @type {number} */ id, /** @type {number} */ i) => queryArray.push(`${i > 0 ? '&' : '?'}idDisplayWZ${i + 1}=${id}`));
   return getFetchAPI(`${URLS.API_CONTEXTS_ZA_IDS}${queryArray.join('')}`);
};

export const getMedia = async function (path) {
   try {
      return getStreamAPI(`${URLS.API_MEDIA}${path}`);
   } catch (err) {
      console.log('error', err);
      throw err;
   }
};

/**
 * @function
 * @param {{ entityName?: string, type?: string, lang?: string }} [params]
 * @return {Promise<{ id: number; version: string; entityName: string; type: string; locales: { [lang: string]: { title: string; text: string } } }[]>}
 */
export const getCurrentTerms = function (params) {
   const baseUrlParams = '';
   let urlParams = baseUrlParams;
   const queryParams = params || {};
   Object.keys(queryParams).forEach((key) => {
      if (!!queryParams[key]) {
         urlParams += `${urlParams === baseUrlParams ? '?' : '&'}${key}=${queryParams[key]}`;
      }
   });

   return getFetchAPI(`${URLS.API_GET_CURRENT_TERMS}${urlParams}`);
};

export const getUserOptins = function () {
   return getFetchAPI(URLS.API_GET_OPTINS);
};

/**
 * @function
 * @param {{ id: number; accepted: boolean }[]} optinList
 * @returns {Promise<{id: number; entityName: string; type: string; accepted: boolean; version: string}[]>}
 */
export const submitOptins = async function (optinList) {
   const result = await apiPost(URLS.API_SUBMIT_OPTINS, { optinList });
   return result.data;
};

/**
 * @function
 * @returns {Promise<{idProfil: string; name: string; active: 0 | 1}[]>}
 */
export const getProfileList = function () {
   return getFetchAPI(URLS.API_PROFIL_LIST);
};

/**
 * @function
 * @param input
 * @returns {Promise<any>}
 */
export const updateUser = function (input) {
   return apiPut(URLS.API_USER_UPDATE, input);
};

/**
 * @function
 * @param {string} password
 * @returns {Promise<any>}
 */
export const apiChangePassword = function (password) {
   return apiPut(URLS.API_PASSWORD_UPDATE, { password });
};

/**
 * @function
 * @param {{ userId: string; email: string }} input
 * @returns {Promise<any>}
 */
export const apiResetPassword = function (input) {
   return apiPut(URLS.API_PASSWORD_RESET, input);
};

export const getMission = function (id) {
   return getFetchAPI(`${URLS.API_GET_MISSION}?id=${id}`);
};

/**
 * @function
 * @param {number} id mission id
 * @param {string} comment comment string
 * @returns {Promise<any>}
 */
export const postMissionComment = async function (id, comment) {
   const response = await apiPatch(URLS.API_POST_MISSION_COMMENT, { id, comment });
   return response.data;
};

/**
 * @function
 * @param {number} id mission id
 * @param {string} comment comment string
 * @returns {Promise<any>}
 */
export const endMission = async function (id, comment) {
   return apiPatch(URLS.API_MISSION_END, { id, comment });
};

/**
 * @function
 * @returns {Promise<any>}
 */
export const callGetCacheForUserRefresh = async function () {
   return getFetchAPI(URLS.API_CACHE_REFRESH);
};

/**
 * @function
 * @returns {Promise<any>}
 */
export const callLogoutBack = async function () {
   return apiPost(URLS.API_LOGOUT);
};
