import React, { createContext, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../core/authentication/authProvider';
import { IUser } from '../core/interfaces/IUser';

interface Props {
   children: React.ReactNode;
}

type UserContextProps = {
   user: IUser;
   temporarilyUpdateUser: (updatedUser: { lastName: string; firstName: string; role: string }) => void;
};

export const UserContext = createContext({} as UserContextProps);

const temporaryUser = 'temporaryUser';

export const UserContextProvider = ({ children }: Props) => {
   const { userInformation, justLoggedIn } = useContext(AuthContext);

   const [user, setUser] = useState<IUser>({
      id: '',
      email: '',
      role: '',
      lastName: '',
      firstName: ' ', // space needed
      fullName: '',
      licence: '',
   });

   useEffect(() => {
      !!justLoggedIn && localStorage.removeItem(temporaryUser);
   }, [justLoggedIn]);

   useEffect(() => {
      const storageData = localStorage.getItem(temporaryUser);
      let tempUser = !!storageData ? JSON.parse(storageData) : null;

      setUser({
         id: userInformation.sub,
         email: userInformation.email,
         role: !!tempUser ? tempUser.role : userInformation.extension_Profil,
         lastName: !!tempUser ? tempUser.lastName : userInformation.family_name,
         firstName: !!tempUser ? tempUser.firstName : userInformation.given_name,
         fullName: userInformation.name,
         licence: userInformation.extension_Licence,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const temporarilyUpdateUser = (updatedUser: { lastName: string; firstName: string; role: string }) => {
      setUser({ ...user, lastName: updatedUser.lastName, firstName: updatedUser.firstName, role: updatedUser.role });
      localStorage.setItem(temporaryUser, JSON.stringify(updatedUser));
   };

   return <UserContext.Provider value={{ user, temporarilyUpdateUser }}>{children}</UserContext.Provider>;
};
