import { useContext, useEffect, useState } from 'react';
import { getCurrentTerms, submitOptins } from '../../API/api';
import config from '../../config/config';
import { AuthContext } from '../../core/authentication/authProvider';
import strings from '../../core/localization/localization';
import { TermsContext } from '../../react-context/terms-context';
import Button from '../Button';
import './anais-cgu-terms-consent.scss';

const AnaisCguTermsConsent = () => {
   const [termLocales, setTermLocales] = useState<{ [lang: string]: { title: string; text: string } } | undefined>(undefined);
   const languages = ['fr', 'en']; // TODO set up app wide language management
   const [termsTitle, setTermsTitle] = useState('');
   const [termsText, setTermsText] = useState({ __html: '' });
   const [termsId, setTermsId] = useState<number | null>(null);
   const [lang, setLang] = useState(strings.getLanguage());

   const { checkTermList } = useContext(TermsContext);
   const { logoff } = useContext(AuthContext);

   useEffect(() => {
      (async () => {
         const anaisCgu = await getCurrentTerms({
            entityName: config.terms.entity.anais,
            type: config.terms.type.cgu,
         });
         const { id, locales } = anaisCgu[0];
         setTermsId(id);
         setTermsTitle(locales[lang].title);
         setTermsText({ __html: locales[lang].text });
         setTermLocales(locales);
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const changeLanguage = (localeName: string) => {
      if (!!termLocales) {
         setLang(localeName);
         setTermsTitle(termLocales[localeName].title);
         setTermsText({ __html: termLocales[localeName].text });
      }
   };

   const acceptAnaisCgu = async () => {
      if (typeof termsId === 'number') {
         const result = await submitOptins([{ id: termsId, accepted: true }]);
         checkTermList(result);
      }
   };

   return (
      <div className="anais-cgu-terms-consent">
         <div className="anais-cgu-terms-consent__languages">
            {languages.map((localeName: string) => {
               const notSelected = localeName !== lang ? 'anais-cgu-terms-consent__languages__lang--dimmed' : '';
               return (
                  <div className={`anais-cgu-terms-consent__languages__lang ${notSelected}`} onClick={() => changeLanguage(localeName)} key={localeName}>
                     {localeName}
                  </div>
               );
            })}
         </div>
         <div className="anais-cgu-terms-consent__content">
            <h3 className="anais-cgu-terms-consent__title roboto-gras">{termsTitle}</h3>
            <div dangerouslySetInnerHTML={termsText} className="anais-cgu-terms-consent__content__text"></div>
         </div>
         <div className="anais-cgu-terms-consent__buttons">
            <Button color="grey" onClick={logoff}>
               {strings.pwa_terms_anais_cgu_disconnect}
            </Button>
            <Button color="yellow" onClick={() => acceptAnaisCgu()}>
               {strings.pwa_terms_anais_cgu_accept}
            </Button>
         </div>
      </div>
   );
};

export default AnaisCguTermsConsent;
