import packageInfo from '../../package.json';

const config = {
   version: packageInfo.version,

   defaultLocale: 'fr',

   maximumFilesPerContext: 20,
   maximumVideosPerContext: 3,
   maximumVideoSize: 200,

   terms: {
      entity: {
         anais: 'anais',
      },
      type: {
         cgu: 'cgu',
      },
   },

   missionStatus: {
      active: 1,
      ended: 2,
      closed: 3,
      canceled: 4,
   },

   missionCommentMaxLength: 500,

   vapidPublicKey: 'BM9kr0xAn6SJ1KCqm92FtHKcjLOaN90u4LgaYEIQW-ptEgWEHZVl4zrgFWmBEcoGiZ4JUy78MYj6eKyuCBEjQ6U',
};

export default config;
