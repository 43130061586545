import { addPushSubscription } from './api';
import config from '../config/config';

const urlBase64ToUint8Array = (base64String) => {
   const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
   const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

   const rawData = window.atob(base64);
   const outputArray = new Uint8Array(rawData.length);

   for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
   }
   return outputArray;
};

export const requestNotificationPermission = async () => {
   return new Promise((resolve, reject) => {
      const permissionResult = Notification.requestPermission((result) => {
         resolve(result);
      });
      if (permissionResult) {
         permissionResult.then(resolve, reject);
      }
   }).then((permissionResult) => {
      if (permissionResult !== 'granted') {
         console.log('Notifications declined');
      } else {
         subscribeToNotification();
      }
   });
};

const subscribeToNotification = async () => {
   try {
      const registration = await navigator.serviceWorker.ready;

      const vapidPublicKey = config.vapidPublicKey;
      const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);

      if (!!registration) {
         const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: convertedVapidKey,
         });
         return addPushSubscription(subscription);
      }
   } catch (err) {
      console.error(err);
   }
};
