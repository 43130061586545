import Button from '../Button';
import Modal from '../Modal';
import strings from '../../core/localization/localization';

interface Props {
   content: { title: string; text: string };
   cancel: () => void;
   confirm: () => void;
}

const ModalConfirm = ({ content, cancel, confirm }: Props) => (
   <Modal.Dialog>
      <Modal.Header close={cancel}>{content.title}</Modal.Header>
      <Modal.Text>{content.text}</Modal.Text>
      <Modal.Footer>
         <Button color="grey" className="modal-confirm__buttons__cancel" onClick={cancel}>
            {strings.pwa_default_cancel}
         </Button>
         <Button onClick={confirm}>{strings.pwa_default_submit}</Button>
      </Modal.Footer>
   </Modal.Dialog>
);

export default ModalConfirm;
