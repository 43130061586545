import { useContext, useEffect } from 'react';
import './message.scss';

import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';

import { MessageContext } from '../../react-context/message-context';

const Message = () => {
   const { message, setMessage, clearMessage } = useContext(MessageContext);

   useEffect(() => {
      if (!!message.text && typeof message.timer === 'number') {
         setTimeout(() => clearMessage(), message.timer);
      }
   }, [message, setMessage, clearMessage]);

   if (!message.text) return null;

   return (
      <div className={`app-wide-message app-wide-message--${message.type === 'danger' || !message.type ? 'danger' : 'success'}`}>
         <div className="app-wide-message__message">
            {(message.type === 'danger' || !message.type) && (
               <div className="app-wide-message__message__warning-icon">
                  <ErrorIcon />
                  &nbsp;&nbsp;
               </div>
            )}
            <div className="app-wide-message__message__text">{message.text}</div>
         </div>
         <div className="app-wide-message__close" onClick={() => clearMessage()}>
            <CloseIcon />
         </div>
      </div>
   );
};

export default Message;
