import React, { createContext, useState } from 'react';

export type appLanguages = 'fr' | 'en';
interface Props {
   children: React.ReactNode;
}

interface ISyncContext {
   isSynchronizing: boolean;
   setIsSynchronizing: (bool: boolean) => void;
   unSyncedCount: number;
   setUncyncedCount: (nb: number) => void;
   appLanguage: appLanguages;
   setAppLanguage: (lang: appLanguages) => void
}

export const SyncContext = createContext({} as ISyncContext);

export const SyncContextProvider = ({ children }: Props) => {
   const [isSynchronizing, setIsSynchronizing] = useState(false);
   const [unSyncedCount, setUncyncedCount] = useState(0);
   const [appLanguage, setAppLanguage] = useState('fr' as appLanguages);

   const statesAndDispatches = {
      isSynchronizing,
      setIsSynchronizing,
      unSyncedCount,
      setUncyncedCount,
      appLanguage,
      setAppLanguage
   };

   return <SyncContext.Provider value={statesAndDispatches}>{children}</SyncContext.Provider>;
};
