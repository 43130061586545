import { BrowserCacheLocation, Configuration, InteractionRequiredAuthError, InteractionType, LogLevel, PublicClientApplication, SilentRequest } from '@azure/msal-browser';
import { MsalProvider, useIsAuthenticated, useMsal, useMsalAuthentication } from '@azure/msal-react';
import { createContext } from 'react';
import { callLogoutBack } from '../../API/api';
import * as dexieHelper from '../../API/dexieApi';
import URLS from '../../API/urls';
import { IMsalUser } from '../interfaces/IUser';

const loginAuthority = `https://${URLS.AZURE_OIDC_B2C_LOGIN}/${URLS.AZURE_OIDC_TENANT}/${URLS.AZURE_SIGNIN}`;
const wellKnownAuthorities = [`https://${URLS.AZURE_OIDC_B2C_LOGIN}/${URLS.AZURE_OIDC_TENANT}/${URLS.AZURE_SIGNIN}/v2.0/.well-known/openid-configuration`];

const passwordResetUrl = [
   `https://${URLS.AZURE_OIDC_B2C_LOGIN}/${URLS.AZURE_OIDC_TENANT}/oauth2/v2.0/authorize`,
   `?p=${URLS.AZURE_RESET}`,
   `&client_id=${URLS.AZURE_APPID}`,
   '&nonce=defaultNonce',
   `&redirect_uri=${window.location.origin}`,
   '&scope=openid',
   `&response_type=code`,
   '&prompt=login',
   '&code_challenge=X3Aqenlz8ChvaEOWSruPBjdiG84ZDGbzSEzaz-dN',
   '&code_challenge_method=S256',
].join('');

const config: Configuration = {
   auth: {
      clientId: URLS.AZURE_APPID,
      authority: loginAuthority,
      knownAuthorities: wellKnownAuthorities,
      postLogoutRedirectUri: window.location.origin,
      redirectUri: window.location.origin,
      navigateToLoginRequestUrl: false,
   },
   system: {
      loggerOptions: {
         logLevel: LogLevel.Warning,
         piiLoggingEnabled: false,
         loggerCallback: (_logLevel, message, _containsPii) => {
            console.log('[MSAL]', message);
         },
      },
   },
   cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false,
   },
};

/* MSAL Instance */
const msalInstance = new PublicClientApplication(config);

export const getToken = async () => {
   const accounts = msalInstance.getAllAccounts();
   if (accounts.length > 0) {
      const request: SilentRequest = {
         scopes: URLS.AZURE_SCOPE,
         account: accounts[0],
      };
      try {
         const response = await msalInstance.acquireTokenSilent(request);
         return response.accessToken;
      } catch (error) {
         if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return msalInstance.acquireTokenRedirect(request);
         }
         return null;
      }
   }
   return null;
};

export const logoffWithoutContext = async () => {
   await dexieHelper.clearAllDatas();
   await callLogoutBack();
   msalInstance.logoutRedirect();
};

type AuthContextProps = {
   userInformation: IMsalUser;
   logoff: () => void;
   justLoggedIn: null | {};
};

export const AuthContext = createContext({} as AuthContextProps);

const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
   const isAuthenticated = useIsAuthenticated();

   // msalInstance can be used as well, but useMsal hook used here in case we want to move context provider to another file
   const { instance } = useMsal();

   const request = {
      scopes: URLS.AZURE_SCOPE,
      redirectStartPage: window.location.origin,
   };

   const { error, result } = useMsalAuthentication(InteractionType.Redirect, request);

   // password reset redirection
   if (error && error.errorMessage && error.errorMessage.indexOf('AADB2C90118') >= 0) {
      /* instance.loginRedirect with Reset authority was causing issues -> it was setting "isAuthenticated" to true after
    reset but instance was not providing account (nor token for http resquests). Hence, using url instead */
      window.location.replace(passwordResetUrl);
   }

   // in case user cancels password reset
   if (error && error.errorMessage && error.errorMessage.indexOf('AADB2C90091') >= 0) {
      instance.loginRedirect({ authority: loginAuthority, scopes: URLS.AZURE_SCOPE });
   }

   if (isAuthenticated) {
      return (
         <AuthContext.Provider
            value={{
               userInformation: instance.getAllAccounts()[0]?.idTokenClaims as unknown as IMsalUser,
               logoff: async () => {
                  await callLogoutBack();
                  await dexieHelper.clearAllDatas();
                  instance.logoutRedirect();
               },
               justLoggedIn: result,
            }}
         >
            {children}
         </AuthContext.Provider>
      );
   } else {
      return null;
   }
};

export const AuthProvider = ({ children }: { children: React.ReactNode }) => (
   <MsalProvider instance={msalInstance}>
      <AuthContextProvider>{children}</AuthContextProvider>
   </MsalProvider>
);
