import { getMedia, updateContext, uploadFile } from '../../API/api';
import * as dexieHelper from '../../API/dexieApi';
import { getCurrentContext, getFieldCondisp } from './conditionHelper';

export const setZAObject = (/** @type {any} */ contextData, /** @type {import('../models/warningZone').WarningZone} */ zaItem) => {
   return dexieHelper.updateOrCreate(contextData, zaItem);
};

export const getZAObject = (idDisplayWZ) => {
   return dexieHelper.getZAObject(idDisplayWZ.toString());
};

export const saveTempValue = (value, key) => {
   return dexieHelper.saveTemp(value, key);
};

export const deleteTempValue = () => {
   dexieHelper.deleteTemp();
};

export const cleanContextDb = (zaIdsDisplay) => {
   return dexieHelper.cleanContextCollection(zaIdsDisplay);
};

export const saveTempForReal = async (idDisplayWZ, index) => {
   let [response, za] = await Promise.all([dexieHelper.getTemp(), getZAObject(idDisplayWZ)]);
   za = updateDelta(response, za, index);
   za.primaryContext.pourcentageCompletion = updatePercentageOfContextWithDelta(za);

   await dexieHelper.saveZa(za);
   dexieHelper.deleteTemp();

   return null;
};

export const removeMedia = async (name) => {
   return dexieHelper.deleteMedia(name);
};

// extracted from dexie helper
const updateDelta = (response, za, index) => {
   if (za !== undefined) {
      if (za.delta === undefined) {
         za.delta = [];
      }
      let contextDelta = za.delta.find((item) => {
         return item.index === index;
      });
      if (contextDelta === undefined) {
         contextDelta = { index, values: [] };
         za.delta.push(contextDelta);
      }

      response.forEach((temp) => {
         let valueDelta = contextDelta.values.find((item) => {
            return item.id === temp.key;
         });
         if (undefined === valueDelta) {
            valueDelta = { id: temp.key, value: undefined };
            contextDelta.values.push(valueDelta);
         }
         valueDelta.value = temp.value;
      });
      za.deltaSaved = false;
   }
   return za;
};

export const updatePercentageOfContextWithDelta = (za) => {
   const tempZa = JSON.parse(JSON.stringify(za));
   const delta = tempZa.delta.find((d) => d.index === 0);
   let percentage = za.primaryContext.pourcentageCompletion;

   if (!!delta && delta.values.length > 0) {
      const apiFieldWithoutPhoto = tempZa.primaryContext.listFields.filter((item) => item.type !== 'PHOTO');
      let APIData = [];

      apiFieldWithoutPhoto.forEach((listField) => {
         listField.subfields.forEach((subfield) => {
            APIData = APIData.concat(subfield);
         });
      });

      let photosSaved = Array.isArray(tempZa.primaryContext.photos) && !!tempZa.primaryContext.photos.length;

      const deltaFields = delta.values.map((item) => {
         if (item.id === 'PHOTO' && Array.isArray(item.value)) {
            photosSaved = !!item.value.length;
         }
         return { id: item.id, value: item.value.value };
      });

      const listfield = makeListfield(APIData, deltaFields);

      percentage = reevaluateContextePercentage(listfield, za, photosSaved);
   }

   return percentage;
};

/** @return {Promise<any>} */
export const getUpdatedValues = async (idDisplayWZ, index) => {
   let [temp, za] = await Promise.all([dexieHelper.getTemp(), getZAObject(idDisplayWZ)]);
   let APIData = [];
   const context = getCurrentContext(za, index);
   context.listFields.forEach((listField) => {
      listField.subfields.map((subfield) => {
         APIData = APIData.concat(subfield);
         return '';
      });
   });

   let delta = za.delta.find((item) => {
      return item.index === index;
   });

   if (undefined === delta) {
      delta = [];
   } else {
      delta = delta.values.map((item) => {
         return { id: item.id, value: item.value.value };
      });
   }

   if (temp) {
      temp = temp.map((item) => {
         const copy = Object.assign({}, item);
         copy.value = copy.value.value;
         return copy;
      });
   }

   return makeListfield(APIData, delta, temp);
};

export const makeListfield = (base, secondAdd, thirdAdd = undefined) => {
   return base.map((item) => {
      let newItem = undefined;
      if (undefined !== thirdAdd) {
         newItem = thirdAdd.find((third) => {
            return third.key + '' === item.id + '';
         });
      }
      if (undefined === newItem) {
         newItem = secondAdd.find((second) => {
            return second.id + '' === item.id + '';
         });
      }

      let newValue = item.value;
      if (undefined !== newItem && undefined !== newItem.value) {
         newValue = newItem.value;
      }

      if (null === newValue) {
         newValue = '';
      }
      return { id: item.id, value: newValue };
   });
};

export const createContextUpdate = (za, zaIndex, ctx, ctxIndex, polygon, name, mapImg) => {
   const delta = za.delta.find((item) => {
      return item.index === ctxIndex;
   });
   let deltaFields = ctx.listFields;
   let comment;

   const apiFieldWithoutPhoto = ctx.listFields.filter((item) => {
      if (item.type === 'COMMENT') {
         comment = item.subfields[0].value;
      }
      return item.type !== 'PHOTO';
   });
   let APIData = [];

   apiFieldWithoutPhoto.forEach((listField) => {
      listField.subfields.forEach((subfield) => {
         APIData = APIData.concat(subfield);
      });
   });

   let photo = ctx.photos;
   if (delta) {
      deltaFields = delta.values.map((item) => {
         return { id: item.id, value: item.value.value };
      });
      const photosDelta = delta.values.find((item) => item.id === 'PHOTO');
      if (photosDelta) {
         photo = photosDelta.value && !!photosDelta.value.length ? photosDelta.value.filter((p) => !p.metadata).map((p) => p.value) : [];
      }

      const updatedComment = delta.values.find((item) => item.id === 'COMMENT1');

      if (!!updatedComment) {
         comment = updatedComment.value.value;
      }
   }
   const listfield = makeListfield(APIData, deltaFields);
   const contextUpdate = {
      objectId: ctx.objectId,
      idWarningZone: +za.idWarningZone,
      commentaire: comment,
      percentComplet: reevaluateContextePercentage(listfield, za, !!photo.length),
      date: ctx.date,
      listfield: listfield.filter((lf) => lf.id !== 'COMMENT1'), //tableau
      photo,
      polygon,
      mapImg,
      traffic: ctx.listFields.find((elem) => elem.name === 'context_format_name_category').subfields.find((subelem) => subelem.name === 'context_format_route_traffic')?.value,
      category: ctx.listFields.find((elem) => elem.name === 'context_format_name_category').subfields.find((subelem) => subelem.name === 'context_format_route_category')?.value,
   };
   if (name) {
      contextUpdate.name = name;
   }
   return contextUpdate;
};

/** @returns {Promise<any>} */
export const saveDataNow = async (contextId, lang, returnUpdatedContext = true) => {
   const za = await getZAObject(contextId);
   if (za.delta !== undefined) {
      let failedUploads = false;
      const mediaDeletionQueue = [];

      // check if there is a photo to upload
      // let newContextWithDeltaValue = undefined; // deep clone context (les subfields) avec json stringify
      for (const delta of za.delta) {
         //mettre a jour newContextWithDeltaValue avec la valeur du delta[d]
         if (delta) {
            const i = delta.values.findIndex((item) => item.id === 'mediaToDelete');

            if (i >= 0 && Array.isArray(delta.values[i].value) && !!delta.values[i].value.length) {
               mediaDeletionQueue.push(...delta.values[i].value);
               delta.values.splice(i, 1);
            }

            const photoDeltas = delta.values.find(({ id }) => id === 'PHOTO');
            if (photoDeltas) {
               for (const photoDelta of photoDeltas.value) {
                  if (photoDelta.metadata && photoDelta.metadata.file) {
                     const dataUpload = new FormData();
                     dataUpload.append('fileName', photoDelta.value);
                     dataUpload.append('contextObjectId', za.primaryContext._id);
                     dataUpload.append('idZoneAlerte', za.idDisplayWZ);
                     dataUpload.append('photo', photoDelta.metadata.file);
                     const uploadResponse = await uploadFile(dataUpload);

                     // remove metadata (and other unnecessary properties) if media upload is successful
                     if (!!uploadResponse) {
                        delete photoDelta.metadata;
                        delete photoDelta.src;
                        delete photoDelta.video;
                     } else {
                        failedUploads = true;
                     }
                  }
               }
            }
         }
      }

      /* update delta to take into account potential media upload failures
        and to avoid uploading successfully uploaded media again if user renews save operation later */
      await dexieHelper.updateDelta(contextId, za.delta);

      // subcontext(s)
      const subsResponse = za.subcontextList.map((item, key) => {
         return createContextUpdate(za, contextId, item, key + 1, item.polygon, item.name, item.mapImg);
      });

      // primary context
      const mainContextUpdate = createContextUpdate(za, contextId, za.primaryContext, 0, null, null, null);

      try {
         const response = await updateContext({
            mainContext: mainContextUpdate,
            subContexts: subsResponse,
            mediaDeletionQueue,
            lang,
            returnUpdatedContext,
         });

         if (response.status >= 200 && response.status < 300) {
            if (!failedUploads) {
               await dexieHelper.setDeltaSaved(za.idDisplayWZ);
               if (returnUpdatedContext) {
                  await dexieHelper.updateOrCreate(response.data, za);
               }
               return { success: true };
            } else {
               return { success: false };
            }
         } else {
            return { success: false };
         }
      } catch (err) {
         return { success: false };
      }
   }
};

export const reevaluateContextePercentage = (contexteInput, za, photosSaved) => {
   const sfieldObj = za.primaryContext.listFields.reduce(
      (obj1, lf) => ({
         ...obj1,
         ...lf.subfields.reduce((obj2, sf) => ({ ...obj2, [sf.id]: sf.sfield }), {}),
      }),
      {}
   );

   let numberOfExpectedFields = 1; // initialized at 1 as photos are expected
   let numberOfFilledFields = 0;
   contexteInput.forEach((field) => {
      const condisp = getFieldCondisp(contexteInput, sfieldObj[field.id]);
      if (condisp) {
         numberOfExpectedFields++;

         if (field.value && field.value !== undefined && field.value !== '' && !!field.value.length && field.value !== 'context_format_default_undefined') {
            numberOfFilledFields++;
         }
      }
   });

   if (!!photosSaved) {
      numberOfFilledFields++;
   }

   let pourcentageCompletion = Math.round((numberOfFilledFields / numberOfExpectedFields) * 100);

   if (pourcentageCompletion < 0) {
      pourcentageCompletion = 0;
   }
   return pourcentageCompletion;
};

export const saveAllDataNow = async (lang) => {
   const zaDelta = await dexieHelper.getAllZAWithDelta();
   let response = { success: true };

   for (const item of zaDelta) {
      const res = await saveDataNow(item.idDisplayWZ, lang, false);
      if (!res.success) {
         response = res;
      }
   }

   return response;
};

export const getToSyncNumber = async () => {
   return dexieHelper.getAllZAWithDelta().then((zaDelta) => {
      if (zaDelta === false) {
         return -1;
      }
      if (zaDelta !== undefined) {
         return zaDelta.length;
      }
      return 0;
   });
};

export const copyPrimaryContext = async (idDisplayWZ, index, callback) => {
   const primaryData = await getUpdatedValues(idDisplayWZ, 0);
   const delta = primaryData.map((item) => {
      if (Array.isArray(item.value)) {
         return { key: item.id, value: item.value.map((v) => ({ value: v })) };
      } else if ('COMMENT1' === item.id) {
         return { key: item.id, value: { value: '' } };
      } else {
         return { key: item.id, value: { value: item.value } };
      }
   });
   let zoneAlerte = await getZAObject(idDisplayWZ);
   zoneAlerte = updateDelta(delta, zoneAlerte, index);
   await dexieHelper.saveZa(zoneAlerte);
   callback();
};

export const checkDeltaCompletionPercentages = async (zonesAlerte) => {
   const warnings = [];
   for (const za of zonesAlerte) {
      const ctx = await getZAObject(za.idDisplayWZ);
      let txRemplissageContexte = za.txRemplissageContexte;

      if (!!ctx && !!ctx.delta && ctx.delta.length > 0 && !ctx.deltaSaved) {
         txRemplissageContexte = ctx.primaryContext.pourcentageCompletion;
      }

      warnings.push({ ...za, txRemplissageContexte });
   }
   return warnings;
};

export const cacheMapImages = (contextObj) => {
   const httpRequests = [];

   Object.keys(contextObj).forEach((key) => {
      httpRequests.push(getMedia(`/map-images-za/${contextObj[key][0].idWarningZone}_mapImage.jpg`));
      if (contextObj[key].length > 1) {
         contextObj[key].slice(1).forEach((subcontext) => {
            if (!!subcontext.mapImg) {
               httpRequests.push(getMedia(`/map-images-za/${subcontext.idWarningZone}_${subcontext.mapImg}_mapImage.jpg`));
            }
         });
      }
   });
   Promise.all(httpRequests);
};

export const getCacheImagesMission = (missions) => {
   const httpRequests = [];
   missions.forEach((mission) => {
      if (mission.photoContext1) {
         httpRequests.push(getMedia(`/${mission.photoContext1}`));
      }
      if (mission.photoContext2) {
         httpRequests.push(getMedia(`/${mission.photoContext2}`));
      }
      if (mission.photoWork) {
         httpRequests.push(getMedia(`/${mission.photoWork}`));
      }
      if (mission.photoDetail) {
         httpRequests.push(getMedia(`/${mission.photoDetail}`));
      }
   });
};
