import React, { Component, ReactNode, useContext } from 'react';
import Button from './components/Button';
import { AuthContext } from './core/authentication/authProvider';

interface ErrorBoundaryProps {
   children: ReactNode;
}

interface ErrorBoundaryState {
   hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
   constructor(props: ErrorBoundaryProps) {
      super(props);
      this.state = {
         hasError: false,
      };
   }

   static getDerivedStateFromError(error: Error) {
      return { hasError: true };
   }

   componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
      console.error('Error caught by error boundary:', error, errorInfo);
   }

   render() {
      if (this.state.hasError) {
         return <FallbackComponent />;
      }

      return this.props.children;
   }
}

// Fallback UI component to display when an error occurs
const FallbackComponent: React.FC = () => {
   const { logoff } = useContext(AuthContext);

   return (
      <div>
         <Button color="grey" onClick={logoff}>
            disconnect{' '}
         </Button>
      </div>
   );
};

export default ErrorBoundary;
