import './context-category-edition.scss';

import Arrow from '@material-ui/icons/ArrowBack';
import { useContext, useEffect, useRef, useState } from 'react';
import Loader from 'react-loader-spinner';
import { Link, Navigate, useLocation, useParams } from 'react-router-dom';

import { getCurrentFieldValue, isVisibleField } from '../../core/helpers/conditionHelper';
import * as contextService from '../../core/helpers/contextHelper';
import strings from '../../core/localization/localization';
import ContextTextInputFieldEdition from '../../pages/ContexteTextInputFieldEdition';
import ContextMediaFieldEdition from '../../pages/ContextMediaFieldEdition';
import ContextSelectFieldEdition from '../../pages/ContextSelectFieldEdition';
import { MessageContext } from '../../react-context/message-context';
import { SyncContext } from '../../react-context/sync-context';

const ContextCategoryEdition = ({ lang }) => {
   const { id } = useParams();
   const { state: location } = useLocation();

   const contextId = id;
   const structure = location.structure;
   const data = location.data;
   const index = location.index;
   const idZa = location.id;
   const type = location.type;

   const mounted = useRef(true);

   const { setMessage } = useContext(MessageContext);

   const { isSynchronizing, setIsSynchronizing, setUncyncedCount } = useContext(SyncContext);

   const [values, setValues] = useState(structure);
   const [redirect, setRedirect] = useState(false);
   const [visible, setVisible] = useState({});

   useEffect(() => {
      update();
      window.scroll({ top: 0, left: 0, behavior: 'auto' });

      return () => {
         mounted.current = false;
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const saveContext = async () => {
      await contextService.saveTempForReal(idZa, index);
      sendValue();
   };

   const sendValue = async () => {
      setIsSynchronizing(true);

      const response = await contextService.saveDataNow(contextId, lang);

      if (!!mounted.current) {
         setRedirect(true);
      } else {
         const unSyncedNb = await contextService.getToSyncNumber();
         setUncyncedCount(unSyncedNb);
      }

      if (!response.success) {
         setMessage({
            text: strings.pwa_save_fail_message_context,
            timer: 10000,
         });
      }

      setIsSynchronizing(false);
   };

   const update = async () => {
      values.forEach(async (field) => {
         const display = await isVisibleField(field, idZa, index);
         setVisible((prevVisible) => ({ ...prevVisible, [field.id]: display }));
      });
      setValues(structure);
   };

   const goBack = () => {
      contextService.deleteTempValue();
   };

   if (!!redirect) {
      return <Navigate to={`/ContextList/${contextId}/${index}`} state={{ backFromContextEdit: type }} />;
   }

   return (
      <div className="ListContext_main-container">
         <Link to={{ pathname: `/ContextList/${contextId}/${index}` }} state={{ backFromContextEdit: type }} onClick={() => goBack()}>
            <Arrow className="arrow-back" />
         </Link>
         <div className="ListContext_container">
            <div className="Title-context">
               {strings.pwa_complete_context} : <strong>{strings[values.name]}</strong>
            </div>
            <div>
               {(() => {
                  if (type === 'PHOTO') {
                     return <ContextMediaFieldEdition index={index} data={data} idDisplayWZ={idZa} key={contextId} isLoading={isSynchronizing} />;
                  } else {
                     return values.map((field) => {
                        if (visible[field.id]) {
                           switch (field.type || field.values) {
                              case 'input':
                                 if (field.value === null) field.value = '';
                                 return <ContextTextInputFieldEdition index={index} data={data} idDisplayWZ={idZa} field={field} key={field.id} update={update} />;
                              case 'texte':
                                 return (
                                    <ContextTextInputFieldEdition index={index} data={data} idDisplayWZ={idZa} field={field} key={field.id} contextId={contextId} update={update} />
                                 );
                              case 'select':
                                 return (
                                    <ContextSelectFieldEdition
                                       data={getCurrentFieldValue(data, idZa, index, field.id)}
                                       index={index}
                                       idDisplayWZ={idZa}
                                       field={field}
                                       id={values.type}
                                       key={field.id}
                                       contextId={contextId}
                                       update={update}
                                    />
                                 );
                              default:
                                 return (field.values = 'context_format_default_undefined');
                           }
                        } else {
                           return null;
                        }
                     });
                  }
               })()}
               <div className="save-context">
                  <button type="button" id="save" disabled={isSynchronizing} onClick={saveContext}>
                     {strings.pwa_context_save}
                  </button>
                  {isSynchronizing && (
                     <div className="save-context__sync-indicator">
                        <Loader type="ThreeDots" color="#000" height={40} width={40} />
                     </div>
                  )}
               </div>
            </div>
         </div>
      </div>
   );
};

export default ContextCategoryEdition;
