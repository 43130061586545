import './user-account.scss';

import { Fragment, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { apiChangePassword, apiResetPassword, getProfileList, updateUser } from '../../API/api';
import { LangPropsType } from '../../App';
import Button from '../../components/Button';
import ModalConfirm from '../../components/ModalConfirm';
import config from '../../config/config';
import { AuthContext } from '../../core/authentication/authProvider';
import { IMessage } from '../../core/interfaces/IMessage';
import strings from '../../core/localization/localization';
import environment from '../../enviromnent';
import { MessageContext } from '../../react-context/message-context';
import { UserContext } from '../../react-context/user-context';

const UserAccount: React.FC<LangPropsType> = ({ lang }) => {
   const { logoff } = useContext(AuthContext);
   const { user, temporarilyUpdateUser } = useContext(UserContext);

   const { setMessage }: { setMessage: (m: IMessage) => void } = useContext(MessageContext);

   const [roles, setRoles] = useState<{ idProfil: string; name: string }[]>([]);
   const [form, setForm] = useState({
      lastName: user.lastName,
      firstName: user.firstName,
      role: user.role,
      email: user.email,
   });

   const [warning, setWarning] = useState({ lastName: false, firstName: false });
   const [disabled, setDisabled] = useState(false);

   const [changePassword, setChangePassword] = useState(false);

   const initialPasswordState = [
      { name: 'password', text: 'pwa_password_label', visibility: 'password', value: '', touched: false },
      { name: 'passwordRepeat', text: 'pwa_password_repeat_label', visibility: 'password', value: '', touched: false },
   ];
   const [password, setPassword] = useState(initialPasswordState);
   const [passwordWarning, setPasswordWarning] = useState(false);

   const initialPasswordCheckState = [
      { checkCondition: (str: string) => str.length >= 8, text: 'pwa_password_warning_minimum_characters', conditionMet: false },
      { checkCondition: (str: string) => /\d/.test(str), text: 'pwa_password_warning_digit', conditionMet: false },
      { checkCondition: (str: string) => /[A-Z]/.test(str), text: 'pwa_password_warning_capital', conditionMet: false },
      { checkCondition: (str: string) => /[a-z]/.test(str), text: 'pwa_password_warning_lowercase', conditionMet: false },
   ];

   const [passwordCheck, setPasswordCheck] = useState(initialPasswordCheckState);
   const [passwordsMatch, setPasswordsMatch] = useState(false);
   const [passwordButtonDisabled, setPasswordButtonDisabled] = useState(true);
   const [modalContent, setModalContent] = useState<null | { title: string; text: string; callback: () => void }>(null);
   const [screenOn, setScreenOn] = useState(false);

   useEffect(() => {
      (async () => {
         const result = await getProfileList();
         setRoles(result);
      })();
   }, []);

   const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, required?: 'required') => {
      const { name, value } = e.target;
      if (required) {
         checkRequired(e);
      }
      setForm({ ...form, [name]: value });
   };

   const checkRequired = (e: { target: { value: string; name: string } }) => {
      const { name, value } = e.target;
      const trimmedValue = value.trim();
      const invalid = !!!trimmedValue;
      setDisabled(invalid);
      setWarning({ ...warning, [name]: invalid });
   };

   const trim = (e: { target: { value: string; name: string } }) => {
      const { name, value } = e.target;
      setForm({ ...form, [name]: value.trim() });
   };

   const toggleVisibility = (i: number) => {
      const arr = [...password];
      arr[i].visibility = arr[i].visibility === 'text' ? 'password' : 'text';
      setPassword(arr);
   };

   const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
      const arr = [...password];
      arr[i].value = e.target.value;

      const conditions = [...passwordCheck];
      if (e.target.name === 'password') {
         passwordCheck.forEach((c) => {
            c.conditionMet = c.checkCondition(e.target.value);
         });
         setPasswordCheck(conditions);
      }

      const doPwdsMatch = arr[0].value === arr[1].value;
      const allConditionsMet = !conditions.some((c: { conditionMet: boolean }) => !c.conditionMet);
      const valid = !!doPwdsMatch && !!allConditionsMet;

      setPassword(arr);
      setPasswordWarning(!allConditionsMet);
      setPasswordsMatch(doPwdsMatch);
      setPasswordButtonDisabled(!valid);
   };

   const markAsTouched = (i: number) => {
      const arr = [...password];
      arr[i].touched = true;
      setPassword(arr);
   };

   const submitForm = async () => {
      try {
         const updatedUser = {
            userId: user.id,
            nom: form.lastName,
            prenom: form.firstName,
            nomComplet: `${form.lastName} ${form.firstName}`,
            role: `${form.role}`,
         };

         await updateUser(updatedUser);

         setMessage({
            text: strings.pwa_user_update_success,
            type: 'success',
            timer: 5000,
         });

         temporarilyUpdateUser({ firstName: form.firstName, lastName: form.lastName, role: `${form.role}` });
      } catch (error) {
         setMessage({
            text: strings.pwa_default_error_message,
            type: 'danger',
            timer: 10000,
         });
      }
   };

   const confirmPasswordChange = () => {
      setModalContent({
         title: strings.pwa_password_confirmation_title,
         text: strings.pwa_password_confirmation_text,
         callback: updatePassword,
      });
   };

   const updatePassword = async () => {
      setModalContent(null);
      try {
         await apiChangePassword(password[0].value);

         setPassword(initialPasswordState);
         setPasswordCheck(initialPasswordCheckState);
         setPasswordButtonDisabled(true);
         setPasswordsMatch(false);
         setChangePassword(false);

         setMessage({
            text: strings.pwa_password_update_success,
            type: 'success',
            timer: 5000,
         });
      } catch (_error) {
         setMessage({
            text: strings.pwa_default_error_message,
            type: 'danger',
            timer: 10000,
         });
      }
   };

   const confirmResetPassword = () => {
      setModalContent({
         title: strings.pwa_password_confirmation_title,
         text: strings.pwa_password_reset_text,
         callback: resetPassword,
      });
   };

   const resetPassword = async () => {
      setModalContent(null);
      setScreenOn(true);
      try {
         await apiResetPassword({ userId: user.id, email: user.email });
         setMessage({
            text: strings.pwa_password_reset_success,
            type: 'success',
            timer: 3000,
         });
         setTimeout(() => logoff(), 3000);
      } catch (_error) {
         setScreenOn(false);
         setMessage({
            text: strings.pwa_default_error_message,
            type: 'danger',
            timer: 10000,
         });
      }
   };

   return (
      <>
         <div className="user-account">
            <h4 className="user-account__title">{strings.pwa_account_edit_my_profil}</h4>
            <div className="user-account__form">
               <div className="user-account__form__field">
                  <label htmlFor="lastName" className="user-account__form__field__label">
                     {strings.pwa_account_form_lastname}
                  </label>
                  <input
                     type="text"
                     id="lastName"
                     name="lastName"
                     className={`user-account__form__field__input form-control
                            ${!!warning.lastName ? 'form-control--invalid' : ''}`}
                     value={form.lastName}
                     onChange={(e) => handleChange(e, 'required')}
                     onBlur={trim}
                  />
               </div>
               <div className="user-account__form__field">
                  <label htmlFor="firstName" className="user-account__form__field__label">
                     {strings.pwa_account_form_firstname}
                  </label>
                  <input
                     type="text"
                     id="firstName"
                     name="firstName"
                     className={`user-account__form__field__input form-control
                            ${!!warning.firstName ? 'form-control--invalid' : ''}`}
                     value={form.firstName}
                     onChange={(e) => handleChange(e, 'required')}
                     onBlur={trim}
                  />
               </div>
               <div className="user-account__form__field">
                  <label htmlFor="role" className="user-account__form__field__label">
                     {strings.pwa_account_form_role}
                  </label>
                  <select id="role" name="role" className="user-account__form__field__input form-control" value={form.role} onChange={handleChange}>
                     {roles.map((r: { idProfil: string; name: string }, i) => (
                        <option key={`select-${i + 1}`} value={r.idProfil}>
                           {strings[r.name]}
                        </option>
                     ))}
                  </select>
               </div>
               <div className="user-account__form__field">
                  <label htmlFor="email" className="user-account__form__field__label">
                     {strings.pwa_account_form_email}
                  </label>
                  <input type="text" id="email" name="email" className="user-account__form__field__input form-control" value={form.email} disabled />
               </div>
            </div>
            <div className="user-account__confirm">
               <Link to="/">
                  <Button
                     color="grey"
                     onClick={() => {
                        return;
                     }}
                  >
                     {strings.pwa_default_cancel}
                  </Button>
               </Link>
               <div className="user-account__confirm__space"></div>
               <Button color="yellow" onClick={submitForm} disabled={disabled}>
                  {strings.pwa_default_submit}
               </Button>
            </div>
            <div className="user-account__password">
               <Button color="yellow" onClick={() => setChangePassword(!changePassword)} className="user-account__password__button">
                  {strings.pwa_account_change_password}
               </Button>
               <div className="user-account__password__space"></div>
               <Button color="yellow" onClick={confirmResetPassword} className="user-account__password__button">
                  {strings.pwa_account_reset_password}
               </Button>
            </div>
            {!!changePassword && (
               <>
                  <div className="user-account__form">
                     {password.map((field, i) => {
                        const invalidPWD = field.name === 'password' && password[0].touched && passwordWarning;
                        const invalidPWDRepeat = field.name === 'passwordRepeat' && password[1].touched && !passwordsMatch;

                        return (
                           <Fragment key={`field-${field.name}`}>
                              <div className="user-account__form__field">
                                 <label htmlFor={field.name} className="user-account__form__field__label user-account__form__field__label--wider">
                                    {strings[field.text]}
                                 </label>
                                 <input
                                    type={field.visibility}
                                    maxLength={16}
                                    id={field.name}
                                    name={field.name}
                                    className={`user-account__form__field__input
                                                user-account__form__field__input--narrow 
                                                form-control password-input
                                                ${invalidPWD || invalidPWDRepeat ? 'form-control--invalid' : ''}`}
                                    value={field.value}
                                    onChange={(e) => onPasswordChange(e, i)}
                                    onBlur={() => markAsTouched(i)}
                                 />
                                 <div className={`password-toggle ${field.visibility === 'text' ? 'password-toggle--visible' : ''}`} onClick={() => toggleVisibility(i)} />
                              </div>
                              {invalidPWD && (
                                 <div className="user-account__form__warning">
                                    {passwordCheck.map(
                                       (c) =>
                                          !c.conditionMet && (
                                             <div key={c.text} className="user-account__form__warning__line">
                                                {strings[c.text]}
                                             </div>
                                          )
                                    )}
                                 </div>
                              )}
                              {invalidPWDRepeat && (
                                 <div className="user-account__form__warning">
                                    <div className="user-account__form__warning__line">{strings.pwa_password_warning_do_not_match}</div>
                                 </div>
                              )}
                           </Fragment>
                        );
                     })}
                  </div>
                  <Button onClick={() => confirmPasswordChange()} disabled={passwordButtonDisabled} className="user-account__change-password-confirm-button">
                     {strings.pwa_new_password_submit}
                  </Button>
               </>
            )}
            <div className="user-account__logout">
               <Button color="yellow" onClick={logoff}>
                  {strings.pwa_user_log_out}
               </Button>
            </div>
            <div className="user-account__credits">
               {strings.pwa_account_view_cgu_1}{' '}
               <a href={`${environment.webUrl}/credits`} target="_blank" rel="noreferrer">
                  {strings.pwa_account_view_cgu_2}
               </a>
               <br />
               <br />
               {strings.pwa_account_view_personal_data_policy_1}{' '}
               <a href={`${environment.webUrl}/donnees-personnelles`} target="_blank" rel="noreferrer">
                  {strings.pwa_account_view_personal_data_policy_2}
               </a>
               <br />
               <br />
            </div>
            <div className="user-account__version">
               {strings.pwa_version} {config.version}
            </div>
         </div>
         {modalContent && <ModalConfirm content={modalContent} cancel={() => setModalContent(null)} confirm={modalContent.callback} />}
         {screenOn && <div className="screen" />}
      </>
   );
};

export default UserAccount;
