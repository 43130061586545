import React, { useState, useEffect, createContext } from 'react';
import { getUserOptins } from '../API/api';
import config from '../config/config';

interface Props {
   children: React.ReactNode;
}

export const TermsContext = createContext<any>([]);

export const TermsContextProvider = ({ children }: Props) => {
   const [promptAcceptTerms, setPromptAcceptTerms] = useState(false);

   useEffect(() => {
      (async () => {
         const result = await getUserOptins();
         checkTermList(result);
      })();
   }, []);

   const checkTermList = (termList: { entityName: string; type: string; accepted: boolean }[]) => {
      setPromptAcceptTerms(
         !termList.some((t) => {
            return t.entityName === config.terms.entity.anais && t.type === config.terms.type.cgu && !!t.accepted;
         })
      );
   };

   return <TermsContext.Provider value={{ promptAcceptTerms, checkTermList }}>{children}</TermsContext.Provider>;
};
