import { Component } from 'react';
import '../../components/ContextCategoryEdition/context-category-edition.scss';
import { getCurrentFieldValue } from '../../core/helpers/conditionHelper.js';
import * as contextService from '../../core/helpers/contextHelper.js';
import strings from '../../core/localization/localization';

class ContextTextInputFieldEdition extends Component {
   constructor(props) {
      super(props);
      this.state = {
         newValue: '',
         getData: '',
      };
   }

   componentDidMount() {
      this.setState({ newValue: getCurrentFieldValue(this.props.data, this.props.idDisplayWZ, this.props.index, this.props.field.id) });
   }

   getChildData = (e) => {
      e.preventDefault();
      const childData = e.target.value;
      this.setState({
         getData: childData,
         newValue: childData,
      });
      contextService.saveTempValue({ value: childData }, this.props.field.id);
   };

   render() {
      const field = this.props.field;
      return (
         <div className="content_container" key={field.id}>
            <div className="Container_category-edition">
               <div className="Container_category-title">{strings[field.name]}</div>
               <div className="category_content">
                  <div className="Category-content_bloc-edition" key={field.id}>
                     <input
                        type="text"
                        name="name"
                        placeholder={strings['text_placeholder']}
                        onChange={this.getChildData}
                        value={this.state.newValue === 'context_format_default_undefined' || this.state.newValue === undefined ? '' : this.state.newValue}
                     />
                  </div>
               </div>
            </div>
         </div>
      );
   }
}

export default ContextTextInputFieldEdition;
