import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PanToolIcon from '@material-ui/icons/PanTool';
import { useContext } from 'react';
import Button from '../../components/Button';
import { AuthContext } from '../../core/authentication/authProvider';
import strings from '../../core/localization/localization';

const useStyles = makeStyles((theme) => ({
   unauthorizedPage: {
      minHeight: '100vh',
      backgroundColor: theme.palette.background.default,
   },
   contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100%',
      padding: theme.spacing(4),
   },
   handIcon: {
      fontSize: 350,
   },
   textContainer: {
      textAlign: 'center',
      marginBottom: theme.spacing(4),
   },
   title: {
      fontSize: 54,
      marginBottom: theme.spacing(2),
   },
   subtitle: {
      fontSize: 24,
      color: theme.palette.text.secondary,
   },
}));

const UnauthorizedPage = () => {
   const classes = useStyles();
   const { logoff } = useContext(AuthContext);

   return (
      <Grid container className={classes.unauthorizedPage}>
         <Grid item xs={12} className={classes.contentContainer}>
            <Grid container direction="column" alignItems="center">
               <Grid item>
                  <PanToolIcon className={classes.handIcon} fontSize="large" />
               </Grid>
               <Grid item className={classes.textContainer}>
                  <Typography variant="h1" className={classes.title}>
                     {strings.pwa_access_unauthorized_access}
                  </Typography>
                  <Typography variant="subtitle1" className={classes.subtitle}>
                     {strings.pwa_access_unauthorized_access_msg}
                  </Typography>
               </Grid>
               <Grid item>
                  <Button color="grey" onClick={logoff}>
                     {strings.pwa_terms_anais_cgu_disconnect}
                  </Button>
               </Grid>
            </Grid>
         </Grid>
      </Grid>
   );
};

export default UnauthorizedPage;
