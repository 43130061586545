import { useState, useEffect } from 'react';
import './media-carousel.scss';

import { IPhoto } from '../../core/interfaces/IPhoto';

import Media from '../Media';

import { getContextMediaUrl } from '../../core/helpers/mediaHelper';
import strings from '../../core/localization/localization';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Button } from '@material-ui/core';

interface Props {
   mediaList: IPhoto[];
   selectedMedia: number;
   closeCarousel: () => void;
}

const MediaCarousel = ({ mediaList, selectedMedia, closeCarousel }: Props) => {
   let objUrl: string | undefined = undefined;

   const [mediaIndex, setMediaIndex] = useState(selectedMedia);

   useEffect(() => {
      return () => {
         revokeObjUrl();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleNavClick = (nb: number) => {
      setMediaIndex((prevIndex) => {
         let i = prevIndex + nb;
         const lastIndex = mediaList.length - 1;
         if (i > lastIndex) {
            return 0;
         }
         if (i < 0) {
            return lastIndex;
         }
         return i;
      });
   };

   const revokeObjUrl = () => {
      if (!!objUrl) {
         URL.revokeObjectURL(objUrl);
         objUrl = undefined;
      }
   };

   return (
      <>
         <div className="carousel-dim" onClick={closeCarousel}></div>
         <div className="media-carousel">
            <div className="media-carousel__navigator">
               <div className="media-carousel__navigator__chevron" onClick={() => handleNavClick(-1)}>
                  <ChevronLeftIcon fontSize={'large'} />
               </div>
               {!!mediaList.length && (
                  <div className="media-carousel__navigator__media">
                     {(() => {
                        const media = mediaList[mediaIndex];

                        let path;
                        let directSource;

                        if (!media.metadata) {
                           if (!media.video) {
                              path = `/context/${media.src}`;
                           } else {
                              directSource = getContextMediaUrl(media.value);
                           }
                        } else {
                           if (!media.video) {
                              directSource = media.src;
                           } else {
                              revokeObjUrl();
                              directSource = URL.createObjectURL(media.metadata.file);
                              objUrl = directSource;
                           }
                        }

                        return <Media path={path} directSource={directSource} alt={media.value} video={!!media.video} />;
                     })()}
                  </div>
               )}
               <div className="media-carousel__navigator__chevron" onClick={() => handleNavClick(1)}>
                  <ChevronRightIcon fontSize={'large'} />
               </div>
            </div>
            <div className="media-carousel__footer" onClick={closeCarousel}>
               <Button variant="contained" className="close-button" disableElevation>
                  {strings.pwa_default_close}
               </Button>
            </div>
         </div>
      </>
   );
};

export default MediaCarousel;
