import './index.scss';

import { createRoot } from 'react-dom/client';

import App from './App';
import ErrorBoundary from './ErrorBoundary';
import PwaInstallButton from './components/PwaInstallButton';
import { AuthProvider } from './core/authentication/authProvider';
import { installPwa, promptButtonId, removePromptButton } from './core/helpers/pwaHelper';
import { SyncContextProvider } from './react-context/sync-context';
import { TermsContextProvider } from './react-context/terms-context';
import { UserContextProvider } from './react-context/user-context';
import reportWebVitals from './reportWebVitals';

// pwaHelper needs to be imported here to intercept "beforeinstallprompt" event before Azure redirect
const rootElement = document.getElementById('root') as HTMLElement;
const root = createRoot(rootElement);

root.render(
   <AuthProvider>
      <ErrorBoundary>
         <UserContextProvider>
            <TermsContextProvider>
               <SyncContextProvider>
                  <App />
                  <PwaInstallButton buttonId={promptButtonId} installPwa={installPwa} removePromptButton={removePromptButton} />
               </SyncContextProvider>
            </TermsContextProvider>
         </UserContextProvider>
      </ErrorBoundary>
   </AuthProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
