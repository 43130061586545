import './App.scss';

import { useContext, useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { MemoryRouter, Navigate, useRoutes } from 'react-router-dom';

import React from 'react';
import { getAccessPWA } from './API/api';
import { requestNotificationPermission } from './API/webPush';
import AnaisCguTermsConsent from './components/AnaisCguTermsConsent';
import ContextCategoryEdition from './components/ContextCategoryEdition';
import Header from './components/Header';
import Message from './components/Message';
import strings, { setTranslations } from './core/localization/localization';
import ContextList from './pages/ContextList/ContextList';
import Dashboard from './pages/Dashboard/Dashboard';
import Mission from './pages/Mission';
import UnauthorizedPage from './pages/Unauthorized';
import UserAccount from './pages/UserAccount';
import { MessageContextProvider } from './react-context/message-context';
import { SyncContext } from './react-context/sync-context';
import { TermsContext } from './react-context/terms-context';

export interface LangPropsType {
   lang: string;
}

const App = () => {
   const [loading, setLoading] = useState(true);
   const { promptAcceptTerms } = useContext(TermsContext);
   const { setAppLanguage } = useContext(SyncContext);

   const [userHasAccess, setUserHasAccess] = useState(false); // added
   const [lang, setLang] = useState(strings.getLanguage());

   useEffect(() => {
      (async () => {
         setAppLanguage(await setTranslations());
         try {
            const respAccessPWA = await getAccessPWA();
            setUserHasAccess(respAccessPWA.includes('PWA_PWA_ACCESS')); // check if the user has access
         } catch (error) {
            console.log(error);
         }
         setLoading(false);
      })();
      requestNotificationPermission();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const changeLang = () => {
      setLang(strings.getLanguage());
   };

   const spinner = (
      <div className="loader-container">
         <Loader type="TailSpin" color="#ffed00" height={100} width={100} timeout={5000} />
      </div>
   );

   return (
      <MessageContextProvider>
         {loading && spinner}
         {userHasAccess ? ( // if the user has access, show the app
            <MemoryRouter>
               <Header updateTrigger={changeLang} />
               <AppRoutes lang={lang} />
            </MemoryRouter>
         ) : (
            // if the user doesn't have access, redirect to UnauthorizedPage
            <UnauthorizedPage />
         )}
         <Message />
         {promptAcceptTerms && <AnaisCguTermsConsent />}
      </MessageContextProvider>
   );

   // THIS COMMENT CAN BE USE TO TRIGGER AN ERROR BOUNDARY EXCEPTION (Rending ERROR to test white screen)
   // return (
   //    <MessageContextProvider>
   //       {loading && spinner}
   //       {userHasAccess ? ( // if the user has access, show the app
   //          <MemoryRouter>
   //             <Header updateTrigger={changeLang} />
   //             <AppRoutes lang={lang} />
   //          </MemoryRouter>
   //       ) : (
   //          // if the user doesn't have access, redirect to UnauthorizedPage
   //          <>
   //             <Header updateTrigger={changeLang} /> {/* Render Header for unauthorized user */}
   //             <UnauthorizedPage />
   //          </>
   //       )}
   //       <Message />
   //       {promptAcceptTerms && <AnaisCguTermsConsent />}
   //    </MessageContextProvider>
   // );
};

const AppRoutes: React.FC<LangPropsType> = ({ lang }) =>
   useRoutes([
      { path: '/', element: <Dashboard lang={lang} /> },
      { path: 'ContextList/:id/:ctxId', element: <ContextList lang={lang} /> },
      { path: 'ContextList/:id', element: <ContextList lang={lang} /> },
      { path: 'Context/:id/modify', element: <ContextCategoryEdition lang={lang} /> },
      { path: 'account', element: <UserAccount lang={lang} /> },
      { path: 'mission/:id', element: <Mission lang={lang} /> },
      { path: '*', element: <Navigate to="/" /> },
   ]);

export default App;
