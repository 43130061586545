import React, { useState, createContext } from 'react';
import { IMessage } from '../core/interfaces/IMessage';

interface Props {
   children: React.ReactNode;
}

type MessageContextProps = {
   message: IMessage;
   setMessage: (m: IMessage) => void;
   clearMessage: () => void;
};

export const MessageContext = createContext({} as MessageContextProps);

export const MessageContextProvider = ({ children }: Props) => {
   const emptyMessage: IMessage = { text: '', type: '', timer: 0 };

   const [message, setMessage] = useState(emptyMessage);

   const clearMessage = () => setMessage(emptyMessage);

   return <MessageContext.Provider value={{ message, setMessage, clearMessage }}>{children}</MessageContext.Provider>;
};
