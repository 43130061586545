import { Component } from 'react';
import '../../components/ContextCategoryEdition/context-category-edition.scss';
import * as contextService from '../../core/helpers/contextHelper.js';
import strings from '../../core/localization/localization';

class ContextSelectFieldEdition extends Component {
   constructor(props) {
      super(props);
      this.state = {
         getData: '',
         realValue: this.props.data,
      };
   }

   getChildData(e, realValue) {
      this.setState({
         getData: e.target.value,
         realValue,
      });
      contextService.saveTempValue({ value: realValue }, this.props.field.id, this.props.idDisplayWZ, this.props.index).then(() => {
         this.props.update();
      });
   }

   render() {
      const field = this.props.field;
      return (
         <div className="content_container" key={field.id}>
            <div className="Container_category-edition">
               <div className="Container_category-title">{strings[field.name]}</div>
               <div className="category_content">
                  {field.values.map((value) => {
                     if (value === this.state.realValue || ((this.state.realValue === '' || this.state.realValue === undefined) && value === 'context_format_default_undefined')) {
                        var className = 'active';
                     } else {
                        className = '';
                     }
                     return (
                        <div className={`Category-content_bloc-edition ` + className} key={field.id + value}>
                           <input type="button" value={strings[value]} onClick={(e) => this.getChildData(e, value)} />
                        </div>
                     );
                  })}
               </div>
            </div>
         </div>
      );
   }
}

export default ContextSelectFieldEdition;
