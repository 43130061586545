import LocalizedStrings from 'react-localization';

import { getTranslations, getUserLicenceLanguage } from '../../API/api';
import { appLanguages } from '../../react-context/sync-context';

const folders = ['pwa', 'databases'];

const anaisPwaLocalization = localStorage.getItem('anais-pwa-localization');
const defaultLang = navigator.language.split('-')[0].startsWith('fr') ? 'fr' : 'en';

const strings = new LocalizedStrings({ [defaultLang]: {} });

export const setTranslations = async () => {
   const data = await getTranslations(folders);
   strings.setContent(data);

   const cachedLang = anaisPwaLocalization?.split('-')[0];
   if (cachedLang) {
      strings.setLanguage(cachedLang);
      return cachedLang as appLanguages;
   }

   const licenceLanguage = await getUserLicenceLanguage();
   const lowerCaseLicenceLang = licenceLanguage.toLowerCase();
   strings.setLanguage(lowerCaseLicenceLang);
   localStorage.setItem('anais-pwa-localization', lowerCaseLicenceLang);
   return lowerCaseLicenceLang as appLanguages;
};

export default strings as any;
