import './button.scss';

interface Props {
   color?: 'grey' | 'yellow';
   onClick?: () => void;
   children?: React.ReactNode;
   className?: string;
   disabled?: boolean;
}

const Button = ({ color, onClick, children, className, disabled }: Props) => (
   <button
      type="button"
      onClick={() => onClick && onClick()}
      className={`custom-button custom-button--${!!color ? color : 'yellow'} ${!!className ? className : ''}`}
      disabled={disabled}
   >
      {children}
   </button>
);

export default Button;
