import environment from '../enviromnent';

const { hostname, azureb2cRedirect, webserviceUrl, azureStorageURI, azureTenantName, azureAppId, azureSigninPolicy, azureResetPolicy } = environment;

const apiVersion = '/v1';

const URLS = {
   ROOT: hostname,
   BLOB: azureStorageURI,
   // API_MISSION_WARNING_ZONES: webserviceUrl + apiVersion + '/zonearisques/getbymission',
   API_MISSION_WARNING_ZONES: webserviceUrl + apiVersion + '/warningzone/getbymission',
   API_GET_MISSION: webserviceUrl + apiVersion + '/missiononsite/get',
   API_MISSION_END: webserviceUrl + apiVersion + '/missiononsite/end',
   API_POST_MISSION_COMMENT: webserviceUrl + apiVersion + '/missiononsite/addComment',
   API_UPDATE_CONTEXT: webserviceUrl + '/v3/contexte/createPWA',
   API_UPLOAD_FILE: webserviceUrl + apiVersion + '/fileupload/uploadcontextphoto',
   API_CONTEXTS_ZA: webserviceUrl + apiVersion + '/contexte/getAllContextes?page=',
   API_CONTEXTS_ZA_IDS: webserviceUrl + '/v3/context/getcontextesfromidlist',
   API_NOTIFICATIONS_SUBSCRIBE: webserviceUrl + apiVersion + '/notifications/addpushsubscription',
   API_TRANSLATIONS_FOLDERS: webserviceUrl + apiVersion + '/translationsmultifolder',
   API_LICENCE_LANGUAGE: webserviceUrl + apiVersion + '/licence/licenceLang',
   API_MEDIA: webserviceUrl + apiVersion + '/media',
   API_GET_OPTINS: webserviceUrl + apiVersion + '/user/me/optins',
   API_SUBMIT_OPTINS: webserviceUrl + apiVersion + '/user/me/submitOptins',
   API_GET_CURRENT_TERMS: webserviceUrl + apiVersion + '/terms/current',
   API_PROFIL_LIST: webserviceUrl + apiVersion + '/profil/list',
   API_USER_UPDATE: webserviceUrl + apiVersion + '/user/update/me',
   API_PASSWORD_UPDATE: webserviceUrl + apiVersion + '/user/update/password',
   API_PASSWORD_RESET: webserviceUrl + apiVersion + '/user/update/resetPassword',
   API_GET_ACCESS_PWA: webserviceUrl + apiVersion + '/warningzone/accessPWA',
   API_CACHE_REFRESH: webserviceUrl + apiVersion + '/cache/getCacheForUser',
   API_LOGOUT: webserviceUrl + apiVersion + '/logout',
   AZURE_REDIRECT_URL: azureb2cRedirect,
   AZURE_OIDC_B2C_LOGIN: `${azureTenantName}.b2clogin.com`,
   AZURE_OIDC_TENANT: `${azureTenantName}.onmicrosoft.com`,
   AZURE_APPID: azureAppId,
   AZURE_SCOPE: [
      `https://${azureTenantName}.onmicrosoft.com/connect/write`,
      `https://${azureTenantName}.onmicrosoft.com/connect/read`,
      `https://${azureTenantName}.onmicrosoft.com/connect/user_impersonation`,
   ],
   AZURE_SIGNIN: azureSigninPolicy,
   AZURE_RESET: azureResetPolicy,
};

export default URLS;
